<template>
  <div ref="mapDiv" class="google-map">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d163.29072302215826!2d19.439532332445456!3d49.09326117393501!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4715a15a33bd8d03%3A0x14499d80a8638f51!2sApartm%C3%A1nov%C3%A1%20chalupa%20v%20Dvore%20u%20maliara!5e0!3m2!1ssk!2ssk!4v1656409605664!5m2!1ssk!2ssk"
      width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"
      referrerpolicy="no-referrer-when-downgrade" class="google-map-frame"></iframe>
  </div>
</template>

<style scoped>

.google-map {
  width: 100%;
  height: 100%;
}

</style>

<!--<script>-->
<!--// eslint-disable-next-line no-undef-->
<!--import { onMounted, ref } from "vue"-->
<!--import { Loader } from "@googlemaps/js-api-loader"-->

<!--const API_KEY = "AIzaSyAnbZ6EbAQvAbT3T2ltlsTyEF1tGjh9htY"-->
<!--const CENTER = { lat: 49.09342446669, lng: 19.440154048872003 }-->
<!--const ZOOM = 14-->

<!--export default {-->
<!--  name: "GoogleMap",-->
<!--  setup () {-->
<!--    const _loader = new Loader({ apiKey: API_KEY })-->
<!--    const mapDiv = ref(null)-->

<!--    onMounted(async () => {-->
<!--      await _loader.load()-->

<!--      if (mapDiv.value) {-->
<!--        const map = new google.maps.Map(mapDiv.value, {-->
<!--          center: CENTER,-->
<!--          zoom: ZOOM,-->
<!--          disableDefaultUI: true,-->
<!--          mapTypeId: google.maps.MapTypeId.ROADMAP-->
<!--        })-->

<!--        const marker = new google.maps.Marker({-->
<!--          position: CENTER,-->
<!--          map: map-->
<!--        })-->
<!--      }-->
<!--    })-->

<!--    return { mapDiv }-->
<!--  }-->
<!--}-->
<!--</script>-->
