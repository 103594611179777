import { createRouter, createWebHistory } from "vue-router"
import Home from "@/views/Home.vue"
import Gallery from "@/views/Gallery"
import Contact from "@/views/Contact"
import Reservation from "@/views/Reservation"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Dvor u Maliara"
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/galeria",
    name: "Gallery",
    component: Gallery
  },
  {
    path: "/kontakt",
    name: "Kontakt",
    component: Contact
  },
  {
    path: "/rezervacie",
    mane: "Rezervácie",
    component: Reservation
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
