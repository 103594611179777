import { createStore } from 'vuex'

const store = createStore({
  state: {
    isMobile: Boolean,
    firstHomeLoad: true
  },
  getters: {
    isMobile: state => state.isMobile,
    isPc: state => !state.isMobile,
    isFirstHomeLoad: state => state.firstHomeLoad
  },
  mutations: {
    setIsMobileAs: (state, payload) => {
      state.isMobile = payload
    },
    setHomeLoaded: (state, payload) => {
      state.firstHomeLoad = payload
    }
  }
})

export default store
