<template>
  <div class="image-viewport-container">
    <div class="image-viewport">
      <div class="image-wrapper" v-for="s in sources" :key="s">
        <div class="snapper"></div>
        <img :src="require('@/assets/photos/' + s)" alt="s">
      </div>
    </div>
    <nav class="image-viewport-nav">
      <button v-for="(s, index) in sources" :key="index" @click="this.to(index)"
              :class="{ selected: index === imageIndex}"></button>
    </nav>
  </div>
</template>

<script>

export default {
  name: "ImageViewport",
  props: {
    sources: {
      type: Array,
      default: () => ["01.jpg"]
    },
    debug: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    sources () {
      this.init()
    }
  },
  data: () => {
    return {
      imageIndex: Number,
      startPos: Number,
      step: Number,
      difference: Number,
      images: []
    }
  },
  methods: {
    init () {
      this.imageIndex = 0
      this.sourcesEven = (this.sources.length % 2) === 0
      this.startPos = this.difference * ((this.sources.length / 2) - 0.5)

      this.reanimate()
    },
    reanimate () {
      const viewport = document.querySelector(".image-viewport")
      if (viewport.children[this.imageIndex]) {
        viewport.children[this.imageIndex]
          .scrollIntoView({ behavior: "smooth", block: "nearest", inline: "start" })
      }
    },
    prev () {
      this.imageIndex = (this.imageIndex === 0) ? this.sources.length - 1 : this.imageIndex - 1
      this.reanimate()
    },
    next () {
      this.imageIndex = (this.imageIndex === this.sources.length - 1) ? 0 : this.imageIndex + 1
      this.reanimate()
    },
    to (index) {
      console.log("clicked" + index)
      this.imageIndex = index
      this.reanimate()
    }
  }
}

</script>

<style>

.image-viewport-container {
  position: relative;
}

.image-viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50px;
  left: 0;
  display: flex;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.image-viewport::-webkit-scrollbar {
  display: none;
}

.image-viewport .image-wrapper {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  counter-increment: item;
}

.image-viewport .snapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  scroll-snap-align: center;
}

.image-viewport img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}

.image-viewport-nav {
  position: absolute;
  inset: auto 0 5px 0;
  display: flex;
  justify-content: center;
}

.image-viewport-nav button {
  text-decoration: none;
  cursor: pointer;
  background-color: var(--highlight-transparent);
  border: none;
  border-radius: 50%;
  position: relative;
  margin: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-viewport-nav button::after {
  content: '';
  position: absolute;
  transform: scale(0);
  transition: 300ms ease;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--highlight)
}

.image-viewport-nav button.selected::after {
  transform: scale(1);
}

@media only screen and (min-width: 1200px) {
  .image-viewport-nav button {
    width: 30px;
    height: 30px;
  }

  .image-viewport-nav button::after {
    width: 20px;
    height: 20px;
  }
}

/*.image-viewport .debug {*/
/*  position: fixed;*/
/*  inset: auto auto 0 0;*/
/*  background-color: white;*/
/*}*/

</style>

<!--<template>-->
<!--  <div class="image-viewport">-->
<!--    <div class="image-wrapper">-->
<!--      <img v-if="this.validSources" :src="this.currentSource" alt='apartmán dvor u maliara' class="main-image">-->
<!--    </div>-->
<!--    <div v-if="this.controls" class="viewport-controls">-->
<!--      <div class="viewport-controls-wrapper">-->
<!--        <button @click="previous">&larr;</button>-->
<!--        <button @click="next">&rarr;</button>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  name: "ImageViewport",-->
<!--  data: () => {-->
<!--    return {-->
<!--      currentIndex: 0-->
<!--    }-->
<!--  },-->
<!--  props: {-->
<!--    autoplay: {-->
<!--      type: Boolean,-->
<!--      default: false-->
<!--    },-->
<!--    delay: {-->
<!--      type: Number,-->
<!--      default: 6000-->
<!--    },-->
<!--    sources: {},-->
<!--    controls: {-->
<!--      type: Boolean,-->
<!--      default: false-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    next () {-->
<!--      if (this.currentIndex === (this.sources.length - 1)) {-->
<!--        this.currentIndex = 0-->
<!--      } else {-->
<!--        this.currentIndex++-->
<!--      }-->
<!--    },-->
<!--    previous () {-->
<!--      if (this.currentIndex === 0) {-->
<!--        this.currentIndex = this.sources.length - 1-->
<!--      } else {-->
<!--        this.currentIndex&#45;&#45;-->
<!--      }-->
<!--    },-->
<!--    to (index) {-->
<!--      if (index > 0 && index < this.sources.length) {-->
<!--        this.currentIndex = index-->
<!--      }-->
<!--    },-->
<!--    disableLoading () {-->
<!--    }-->
<!--  },-->
<!--  computed: {-->
<!--    validSources () {-->
<!--      return (this.sources.length > 0)-->
<!--    },-->
<!--    currentSource () {-->
<!--      return require("@/assets/photos/" + this.sources[this.currentIndex])-->
<!--    }-->
<!--  },-->
<!--  mounted () {-->
<!--    if (this.autoplay) {-->
<!--      setInterval(this.next, this.delay)-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style scoped>-->

<!--.image-viewport {-->
<!--  position: relative;-->
<!--}-->

<!--.image-wrapper {-->
<!--  position: absolute;-->
<!--  inset: 0 0 0 0;-->
<!--}-->

<!--.main-image {-->
<!--  width: 100%;-->
<!--  height: 100%;-->
<!--  object-fit: cover;-->
<!--}-->

<!--.viewport-controls {-->
<!--  position: absolute;-->
<!--  inset: 0 0 0 0;-->

<!--  display: flex;-->
<!--  flex-flow: column nowrap;-->
<!--  justify-content: flex-end;-->
<!--  align-items: center;-->

<!--  opacity: 0;-->
<!--  transition: 300ms ease;-->
<!--  box-shadow: 0px 0px 10px var(&#45;&#45;highlight);-->
<!--}-->

<!--.viewport-controls:hover {-->
<!--  opacity: 1;-->
<!--}-->

<!--.viewport-controls-wrapper {-->
<!--  background-color: var(&#45;&#45;highlight-transparent);-->
<!--  padding: 5px;-->
<!--  margin-bottom: 15px;-->
<!--  border-radius: 5px;-->
<!--}-->

<!--.viewport-controls button {-->
<!--  color: black;-->
<!--  padding: 10px 15px;-->
<!--  margin: 0;-->
<!--  text-align: center;-->
<!--  border: none;-->
<!--  background-color: transparent;-->
<!--  cursor: pointer;-->
<!--  border-radius: 5px;-->

<!--  transition: 300ms ease;-->
<!--}-->

<!--.viewport-controls button:hover {-->
<!--  background-color: var(&#45;&#45;highlight);-->
<!--}-->

<!--</style>-->
