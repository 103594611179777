<template>
  <div class="gallery">
    <div id="gal-viewport" :class="{hidden: this.gNav.mouseOverNav && this.$store.getters.isPc}">
      <div id="gal-viewport-content">
        <div v-for="(imgSource, index) in this.sources[this.currentSourceIndex]" :key="index" :class="{isLast: index === this.sources[this.currentSourceIndex].length - 1 && this.$store.getters.isMobile,
                      isFirst: index === 0 && this.$store.getters.isMobile}" class="img-wrapper">
          <img :src="require('@/assets/photos/' + imgSource)" loading="lazy">
        </div>
      </div>
      <div v-if="this.$store.getters.isPc" class="buttons">
        <button :class="{hidden: this.gViewport.hidePrevBtn}" @click="previous"><img
          src="@/assets/svgs/arrow_left.svg"></button>
        <button :class="{hidden: this.gViewport.hideNextBtn}" @click="next"><img src="@/assets/svgs/arrow_right.svg">
        </button>
      </div>
    </div>
    <nav id="gal-nav" :class="{ hidden: !this.gNav.mouseOverNav && this.$store.getters.isPc }"
         @mouseout="this.gNav.mouseOverNav = false"
         @mouseover="this.gNav.mouseOverNav = true">
      <div v-if="this.$store.getters.isPc" ref="galNavList" class="gallery-nav-list" @resize="updateNavOffset">
        <div v-for="(imgSource, index) in this.sources[this.currentSourceIndex]" :key="index"
             :class="{ selected: index == this.currentIndex}"
             class="img-wrapper">
          <img :src="require('@/assets/photos/' + imgSource)" loading="eager" @click="to(index)"
               @load="updateNavOffset">
        </div>
      </div>
      <SwitchingBar :options="['okolie', 'dvojlôžkový&nbsp;apartmán', 'štvorlôžkový&nbsp;apartmán']"
                    @index-changed=" (index) => {this.currentSourceIndex=index; this.currentIndex=0;}"/>
      <div class="gallery-nav-list-bar"></div>
      <div v-if="this.gNav.debug">
        <!--        <p>Current state (percent): {{ this.gNav.navXPercent }}</p>
                <p>Mouse target (percent): {{ this.gNav.mouseXPercent }}</p>-->
        <p>Pixels overflowing: {{ this.gNav.navXOffset }}</p>
        <!--        <p>Mouse over navigation: {{ this.gNav.mouseOverNav }}</p>
                <p>Current index: {{ this.currentIndex }}</p>-->
      </div>
    </nav>
  </div>
</template>

<script>
import SwitchingBar from "@/components/SwitchingBar"

import { gsap } from "gsap"

export default {
  name: "Locality",
  components: {
    SwitchingBar
  },
  data: () => {
    return {
      sources: [
        ["out1.jpg", "out2.jpg", "out3.jpg", "out4.jpg", "out5.jpg", "out6.jpg"],
        ["h1.jpg", "h2.jpg", "h3.jpg", "h4.jpg", "h5.jpg", "h6.jpg", "h7.jpg"],
        ["s1.jpg", "s2.jpg", "s3.jpg", "s4.jpg", "s5.jpg", "s6.jpg", "s7.jpg"]
      ],
      currentSourceIndex: 0,
      currentIndex: 0,
      gNav: {
        speed: 0.1,
        margin: 30,
        mouseOverNav: false,
        mouseX: 0,
        mouseXPercent: 0,
        navXPercent: 0,
        navXOffset: 0,
        lastScreenWidth: Number,
        debug: false
      },
      gViewport: {
        mouseX: 0,
        viewportXMove: 0,
        hideNextBtn: false,
        hidePrevBtn: false,
        hideMargin: 0.45
      }
    }
  },
  computed: {
    viewportXTarget () {
      return 100 * this.currentIndex
    }
  },
  methods: {
    updateNavOffset () {
      if (window.innerWidth < this.$refs.galNavList?.offsetWidth) {
        this.gNav.navXOffset = (this.$refs.galNavList.offsetWidth - window.innerWidth) + this.gNav.margin
      } else {
        this.gNav.navXOffset = 0
      }
    },
    next () {
      if (this.currentIndex === (this.sources[this.currentSourceIndex].length - 1)) {
        this.currentIndex = 0
      } else {
        this.currentIndex++
      }
    },
    previous () {
      if (this.currentIndex === 0) {
        this.currentIndex = this.sources[this.currentSourceIndex].length - 1
      } else {
        this.currentIndex--
      }
    },
    to (index) {
      if (index >= 0 && index < this.sources[this.currentSourceIndex].length) {
        this.currentIndex = index
      }
    }
  },
  mounted () {
    window.addEventListener("resize", e => {
      if (window.innerWidth >= 1200 && this.gNav.lastScreenWidth < 1200) {
        galNavListSetX = gsap.quickSetter(".gallery-nav-list", "x", "px")
      }
      this.gNav.lastScreenWidth = window.innerWidth
    })
    window.addEventListener("keydown", e => {
      if (e.key === "ArrowRight" || e.key === "") this.next()
      if (e.key === "ArrowLeft") this.previous()
    })
    window.addEventListener("mousemove", e => {
      if (this.gNav.mouseOverNav) {
        this.gNav.mouseX = e.x
      }
      this.gViewport.mouseX = e.x
    })

    let galNavListSetX = gsap.quickSetter(".gallery-nav-list", "x", "px")
    const galNavListBarSetScaleX = gsap.quickSetter(".gallery-nav-list-bar", "scaleX", "%")
    const galViewportSetX = gsap.quickSetter("#gal-viewport-content", "x", "vw")

    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - this.gNav.speed, gsap.ticker.deltaRatio())

      this.gNav.mouseXPercent = (this.gNav.mouseX / window.innerWidth) - 0.5
      if (Math.abs(this.gNav.mouseXPercent - this.gNav.navXPercent) > 0.0001) {
        this.gNav.navXPercent += (this.gNav.mouseXPercent - this.gNav.navXPercent) * dt

        galNavListSetX(this.gNav.navXPercent * -this.gNav.navXOffset)
        galNavListBarSetScaleX((this.gNav.navXPercent + 0.5) * 100)
      }

      this.gViewport.viewportXMove += (-this.viewportXTarget - this.gViewport.viewportXMove) * dt
      galViewportSetX(this.gViewport.viewportXMove)

      this.gViewport.hideNextBtn = window.innerWidth * (1 - this.gViewport.hideMargin) > this.gViewport.mouseX || this.gNav.mouseOverNav
      this.gViewport.hidePrevBtn = window.innerWidth * this.gViewport.hideMargin < this.gViewport.mouseX || this.gNav.mouseOverNav

      this.updateNavOffset()
    })
  }
}
</script>

<style>

#gal-viewport {
  position: fixed;
  inset: 0 0 0 0;
}

#gal-viewport-content {
  height: 100%;
  width: 80%;
  margin: auto;

  display: flex;
  flex-flow: column nowrap;

  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

#gal-viewport-content::-webkit-scrollbar {
  display: none;
}

#gal-viewport .img-wrapper {
  height: 300px;
  width: 100%;
  margin: var(--side-margin) 0;

  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
}

#gal-viewport .img-wrapper.isFirst {
  margin-top: 250px;
}

#gal-viewport .img-wrapper.isLast {
  margin-bottom: 250px;
}

#gal-viewport .img-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

@media only screen and (min-width: 1200px) {
  #gal-viewport {
    inset: var(--nav-height) 0 150px 0;
    transition: opacity 300ms ease;
  }

  #gal-viewport.hidden {
    opacity: 0.9;
  }

  #gal-viewport-content {
    width: auto;
    height: 100%;

    flex-flow: row nowrap;
    align-items: center;

    scroll-snap-type: none;
    overflow-y: initial;
  }

  #gal-viewport .img-wrapper {
    height: 100%;
    width: auto;
    min-width: 100vw;
    margin: 0;

    scroll-snap-align: none;
  }

  #gal-viewport .img-wrapper img {
    width: auto;
    margin: auto 0;
  }

  #gal-viewport .buttons {
    position: absolute;
    inset: 0 var(--side-margin) 0 var(--side-margin);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #gal-viewport .buttons button {
    width: 100px;
    height: 100px;
    border: none;
    background-color: var(--highlight-transparent);
    border-radius: 5px;
    text-align: center;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    transition: opacity 300ms ease;
  }

  #gal-viewport .buttons button.hidden {
    opacity: 0;
  }

  #gal-viewport button img {
    width: 80px;
    height: 80px;
    padding: 20px;
    border-radius: 5px;

    transition: 300ms ease;
  }

  #gal-viewport button img:hover {
    background-color: var(--highlight);
  }
}

</style> <!--Viewport-->

<style>

#gal-nav {
  position: fixed;
  inset: auto 0 0 0;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  background-color: white;

  transition: 300ms ease;
  transition-property: transform, opacity;
}

#gal-nav.hidden {
  transform: translateY(60%);
  opacity: 0.9;
}

#gal-nav .switching-bar {
  flex: 0 1 600px;
  margin: calc(var(--side-margin) / 2) calc(var(--side-margin) / 2);
  flex-wrap: wrap;
  background-color: var(--highlight-transparent);
}

@media only screen and (min-width: 1200px) {
  #gal-nav {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    background-color: white;
  }

  #gal-nav .gallery-nav-list {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
  }

  #gal-nav .gallery-nav-list .img-wrapper {
    height: 200px;
    max-width: 400px;
    margin: 0 5px;
    position: relative;

    transform-origin: bottom;
    transition: 300ms ease;
  }

  #gal-nav .gallery-nav-list .img-wrapper.selected {
    height: 180px;
  }

  #gal-nav .gallery-nav-list .img-wrapper img {
    object-fit: contain;
    height: 100%;
    cursor: pointer;
  }

  #gal-nav .switching-bar {
    flex-basis: 0;
    min-width: 800px;
    margin: 0 0 15px 0;
    background-color: var(--highlight-transparent);
  }

  #gal-nav .gallery-nav-list-bar {
    height: 6px;
    width: 100%;
    transform-origin: left;
    background-color: var(--highlight);
  }
}

</style> <!--Navigation-->
