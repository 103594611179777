<template>
  <nav id="navbar">
    <ul>
      <router-link to="/">domov</router-link>
      <router-link to="/galeria">galéria</router-link>
      <router-link to="/kontakt">kontakt</router-link>
    </ul>
  </nav>
</template>

<script>
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default {
  name: "Navbar",
  data: () => {
    return {
      navShown: false,
      oldScroll: 0,
      buffer: 1
    }
  },
  mounted () {
    window.addEventListener("scroll", this.onScroll)
  },
  unmounted () {
    window.removeEventListener("scroll", this.onScroll)
  },
  methods: {
    toggleNav () {
      if (this.navShown) {
        gsap.to(
          "#navbar",
          0.3,
          { opacity: 0, yPercent: -100, ease: "power2.out" }
        )
      } else {
        gsap.to(
          "#navbar",
          0.3,
          { opacity: 1, yPercent: 0, ease: "power2.out" }
        )
      }
      this.navShown = !this.navShown
    },
    onScroll () {
      if (window.scrollY < 80 && !this.navShown) {
        this.toggleNav()
      }
      if (window.scrollY > 80) {
        if (window.scrollY > (this.oldScroll + this.buffer) && this.navShown) {
          this.toggleNav()
        }
        if (window.scrollY < (this.oldScroll - this.buffer) && !this.navShown) {
          this.toggleNav()
        }
      }
      this.oldScroll = window.scrollY
    }
  }
}

</script>

<style scoped>

#navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  min-width: 320px;
  min-height: var(--nav-height);

  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-color: var(--background-primary)
}

#navbar ul {
  margin: 0 var(--side-margin);
  white-space: nowrap;
}

#navbar a {
  color: var(--text-primary);
  padding: 0 5px;
  margin: 0 10px;
  text-decoration: none;

  position: relative;
  transition: 300ms ease;
}

#navbar a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: var(--highlight);
  transition: 400ms ease;
  transform-origin: left center;
  transform: scaleX(0);
}

#navbar a:hover::after {
  transform: scaleX(1);
}

#navbar a.router-link-exact-active {
  color: darkslategrey;
}

#navbar a.router-link-exact-active::after {
  transform: scaleX(1);
}

/*IPhone SE (2.gen) a väčšie*/
@media only screen and (min-width: 375px) {
  #navbar a {
    padding: 0 10px;
    margin: 0 15px;
  }
}

/*Plná stránka - tablety, notebooky...*/
@media only screen and (min-width: 1200px) {
  #navbar {
    justify-content: left;
    flex-flow: row nowrap;
  }

  #navbar a {
    padding: 0 10px 0 0;
    margin: 0 50px 0 0;
  }

  #navbar p {
    margin-right: var(--side-margin);
    font-size: 1rem;
  }
}

</style>
