import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import primeVue from "primevue/config"
import LoadScript from "vue-plugin-load-script"

const app = createApp(App)

app.use(router)
app.use(store)
app.use(primeVue)
app.use(LoadScript)
app.mount("#app")
