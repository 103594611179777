<template>
  <div :style="styleObj">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  data: () => {
    return {
      styleObj: {}
    }
  },
  props: {
    h: {
      type: String,
      default: '100%'
    },
    w: {
      type: String,
      default: '100%'
    },
    pos: {
      type: String,
      default: 'relative'
    },
    mrg: {
      type: String,
      default: '0'
    },
    pad: {
      type: String,
      default: '0'
    },
    disp: {
      type: String,
      default: 'block'
    }
  },
  created () {
    this.styleObj.width = this.w
    this.styleObj.height = this.h
    this.styleObj.position = this.pos
    this.styleObj.margin = this.mrg
    this.styleObj.padding = this.pad
    this.styleObj.display = this.disp

    this.styleObj['z-index'] = 200
    this.styleObj['min-height'] = this.h
  }
}
</script>

<style scoped>

</style>
