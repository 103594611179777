<template>
  <div id="contact">
    <div class="content">
      <div class="header-button-wrapper">
        <h1>kontakt</h1>
        <div v-if="this.$store.getters.isPc" class="buttons">
          <button class="cfa-button" @click="this.$router.push('/rezervacie')">REZERVÁCIE</button>
          <button class="cfa-button" @click="contactFormOpen = !contactFormOpen">
            {{ !contactFormOpen ? "NAPÍŠTE&nbsp;NÁM" : "ZRUŠIŤ" }}
          </button>
        </div>
      </div>
      <h2>+421 904 669 332</h2>
      <h2>dvorumaliara@gmail.com</h2>
      <p>Liptovský Michal 102, 034 83 Slovensko</p>
      <p>DIC: 102 177 98 69</p>
      <div v-if="this.$store.getters.isMobile" class="buttons">
        <button class="cfa-button" @click="this.$router.push('/rezervacie')">REZERVÁCIE</button>
        <button class="cfa-button" @click="contactFormOpen = !contactFormOpen">NAPÍŠTE&nbsp;NÁM</button>
      </div>
      <GoogleMap v-if="this.$store.getters.isMobile"/>
    </div>
    <ContactForm v-if="this.$store.getters.isPc && this.contactFormOpen" @sending="this.sending = true" @emailSent="() => {
        contactFormOpen = false
        sending = false
      }" :templateParams="this.tp">
      <input v-model="this.tp.from_name" class="name" name="name" placeholder="Meno" required type="text"/>
      <input v-model="this.tp.from_email" class="address" name="email" placeholder="E-mail" required
             type="email"/>
      <textarea v-model="this.tp.message" class="message" required rows="4"/>
      <button class="cfa-button" v-if="!sending" type="submit">ODOSLAŤ</button>
      <div class="lds-dual-ring" v-if="sending"></div>
    </ContactForm>
  </div>
  <div v-if="this.$store.getters.isMobile && this.contactFormOpen" id="fixed-contact-form">
    <button class="cancel-button cfa-button" @click="contactFormOpen = !contactFormOpen">ZRUŠIŤ</button>
    <ContactForm @sending="this.sending = true" @emailSent="() => {
        contactFormOpen = false
        sending = false
      }" :templateParams="this.tp">
      <input v-model="this.tp.from_name" class="name" name="name" placeholder="Meno" required type="text"/>
      <input v-model="this.tp.from_email" class="address" name="email" placeholder="E-mail" required
             type="email"/>
      <textarea v-model="this.tp.message" class="message" required rows="4"/>
      <button class="cfa-button" v-if="!sending" type="submit">ODOSLAŤ</button>
      <div class="lds-dual-ring" v-if="sending"></div>
    </ContactForm>
  </div>
  <Footer/>
</template>

<script>
import Footer from "@/components/Footer"
import GoogleMap from "@/components/GoogleMap"
import ContactForm from "@/components/ContactForm"

export default {
  name: "Contact",
  components: {
    Footer,
    GoogleMap,
    ContactForm
  },
  data: () => {
    return {
      contactFormOpen: false,
      tp: {},
      sending: false
    }
  }
}
</script>

<style>

#contact {
  margin: 0 auto;
  padding: 0 var(--side-margin);
  height: 100vh;
  width: 100%;
  max-width: var(--m-stretch-limit-large);

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

#contact .content {
  padding: 15px;
}

#contact .header-button-wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid black;
}

#contact .content .buttons {
  margin-top: 30px;
  display: flex;
  flex-flow: row wrap;
}

#contact .content .cfa-button {
  flex: 0 1;
  height: 35px;
  padding: 0 50px;
  margin: 15px 15px 0 0;
}

#contact .content h2 {
  font-size: 2em;
  padding: 15px 0 0 0;
  color: var(--text-secondary);
}

#contact .content p {
  padding: 15px 0 0 0;
}

#contact .google-map-frame {
  height: 25vh;
  margin-top: 50px;
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) {
  #contact {
    max-width: none;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  #contact .content {
    flex: 2 1 65%;
    max-width: 1200px;
  }

  #contact .contact-form {
    flex: 1 0 35%;
    margin-left: 50px;
    max-width: 800px;
  }

  #contact .content h2 {
    font-size: 4em;
    text-align: right;
  }

  #contact .content p {
    text-align: right;
  }

  #contact .content .buttons {
    margin: 0;
    display: block;
  }

  #contact .content .cfa-button {
    height: 45px;
    padding: 0 50px;
    margin: 0 0 0 15px;
  }
}

#fixed-contact-form {
  position: fixed;
  inset: 0 var(--side-margin) 0 var(--side-margin);
  z-index: 2000;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;

  background-color: white;
}

#fixed-contact-form .contact-form {
  width: 100%;
  max-width: 640px;
}

#fixed-contact-form .cancel-button {
  min-width: 180px;
  margin-bottom: 40px;
}

</style>
