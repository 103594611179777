<template>
  <footer>
    <div class="content">
      <div class="info-wrapper">
        <nav class="buttons">
          <router-link to="/">domov</router-link>
          <router-link to="/galeria">galéria</router-link>
          <router-link to="/kontakt">kontakt</router-link>
        </nav>
        <div class="info-bottom">
          <p>Dvor u Maliara<br>2022 kk</p>
          <div class="socials">
            <img alt="facebook" src="@/assets/logos/fb.png"
                 @click="openWindow('https://www.facebook.com/pages/Dvor%20u%20Maliara/262814804097078/')">
            <img alt="instagram" src="@/assets/logos/ig.png"
                 @click="openWindow('https://www.instagram.com/dvorumaliara/')">
          </div>
        </div>
      </div>
      <div class="map-wrapper" v-if="this.$store.getters.isPc">
        <GoogleMap/>
      </div>
    </div>
  </footer>
</template>

<script>
import GoogleMap from "@/components/GoogleMap"

export default {
  components: {
    GoogleMap
  },
  methods: {
    openWindow (url) {
      window.open(url, "_blank").focus()
    }
  }
}

</script>

<style>

footer {
  height: auto;
  width: 100vw;

  display: flex;
  align-content: center;
  justify-content: center;

  background-color: transparent;
}

footer .content {
  width: 100%;
  height: 100%;
  margin: 15px;
  max-width: 1500px;

  display: flex;
  flex-flow: row nowrap;
  align-content: center;
  justify-content: center;
}

footer .info-wrapper {
  flex: 2 1;
  padding: 15px;
  max-width: var(--m-stretch-limit);
  background-color: var(--highlight-transparent);
  border-radius: 5px 5px 5px 5px;
}

footer .buttons {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
}

footer .buttons a {
  cursor: pointer;
  color: var(--text-primary);
  margin-bottom: 15px;
  border-right: 1px solid black;
  text-decoration: none;
}

footer .info-bottom {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;

}

footer .info-bottom .socials {
  margin-left: auto;
}

footer .info-bottom .socials img {
  cursor: pointer;
  width: 35px;
  height: 35px;
  margin-left: 15px;
}

footer .map-wrapper {
  flex: 1 1;
}

footer .google-map-frame {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media only screen and (min-width: 1200px) {
  footer .content {
    margin: 30px;
  }

  footer .info-wrapper {
    border-radius: 5px 0 0 5px;
  }

  footer .buttons a {
    font-size: 3em;
    transition: 300ms ease;
  }

  footer .buttons a:hover {
    color: white;
    border-color: white;
  }

  footer .info-bottom .socials img {
    width: 50px;
    height: 50px;
    margin-left: 30px;
  }
}

</style>

<!--<template>-->
<!--  <footer>-->
<!--    <div v-if="this.$store.getters.isPc" class="footer-text">-->
<!--      <div class="fb-ig-wrapper">-->
<!--        <img alt="facebook"-->
<!--             src="@/assets/logos/fb.png"-->
<!--             @click="openWindow('https://www.facebook.com/pages/Dvor%20u%20Maliara/262814804097078/')">-->
<!--        <img alt="instagram" src="@/assets/logos/ig.png" @click="openWindow('https://www.instagram.com/dvorumaliara/')">-->
<!--      </div>-->
<!--      <h2>Dvor u Maliara</h2>-->
<!--      <p>kk 2021</p>-->
<!--    </div>-->
<!--    <GoogleMap v-if="this.$store.getters.isPc"/>-->
<!--    <nav>-->
<!--      &lt;!&ndash;      <router-link to="/rezervacie">rezervácie</router-link>&ndash;&gt;-->
<!--      <router-link to="/">domov</router-link>-->
<!--      <router-link to="/galeria">galéria</router-link>-->
<!--      <router-link to="/kontakt">kontakt</router-link>-->
<!--    </nav>-->
<!--    <div v-if="this.$store.getters.isMobile" class="footer-text">-->
<!--      <img alt="facebook" src="@/assets/logos/fb.png"-->
<!--           @click="openWindow('https://www.facebook.com/pages/Dvor%20u%20Maliara/262814804097078/')">-->
<!--      <img alt="instagram" src="@/assets/logos/ig.png" @click="openWindow('https://www.instagram.com/dvorumaliara/')">-->
<!--      <p>Dvor u Maliara<br/>kk 2021</p>-->
<!--    </div>-->
<!--  </footer>-->
<!--</template>-->

<!--<script>-->
<!--import GoogleMap from "@/components/GoogleMap"-->
<!--import gsap from "gsap"-->
<!--import ScrollTrigger from "gsap/ScrollTrigger"-->

<!--gsap.registerPlugin(ScrollTrigger)-->

<!--export default {-->
<!--  name: "Footer",-->
<!--  components: {-->
<!--    GoogleMap-->
<!--  },-->
<!--  methods: {-->
<!--    openWindow (url) {-->
<!--      window.open(url, "_blank").focus()-->
<!--    },-->
<!--    f1 () {-->
<!--      gsap.from("footer", {-->
<!--        opacity: 0,-->
<!--        y: 50,-->
<!--        ease: "power1",-->
<!--        scrollTrigger: {-->
<!--          trigger: "footer",-->
<!--          start: "top+=25% bottom",-->
<!--          end: "top+=75% bottom",-->
<!--          scrub: true-->
<!--        }-->
<!--      })-->
<!--    }-->
<!--  },-->
<!--  mounted () {-->
<!--    setTimeout(this.f1, 500) /* je mi to ľúto */-->
<!--  }-->
<!--}-->
<!--</script>-->

<!--<style>-->

<!--footer {-->
<!--  width: 100%;-->
<!--  background-color: var(&#45;&#45;highlight-transparent);-->
<!--  padding: var(&#45;&#45;side-margin);-->

<!--  display: flex;-->
<!--  flex-flow: column nowrap;-->
<!--}-->

<!--footer nav {-->
<!--  width: 100%;-->
<!--}-->

<!--footer a {-->
<!--  display: block;-->
<!--  text-transform: lowercase;-->
<!--  text-decoration: none;-->
<!--  text-align: right;-->
<!--  font-size: 2.5em;-->
<!--  border-left: 1px solid var(&#45;&#45;text-secondary);-->
<!--  color: var(&#45;&#45;text-primary);-->
<!--  margin-bottom: 15px;-->
<!--  padding-left: 15px;-->
<!--}-->

<!--footer .footer-text {-->
<!--  display: flex;-->
<!--  flex-flow: row nowrap;-->
<!--  align-items: center;-->
<!--  text-align: right;-->
<!--  margin-top: 15px;-->
<!--}-->

<!--footer .footer-text img {-->
<!--  width: 40px;-->
<!--  height: 40px;-->
<!--  margin: 0 15px 0 0;-->
<!--  cursor: pointer;-->
<!--}-->

<!--footer .footer-text p {-->
<!--  margin-left: auto;-->
<!--}-->

<!--@media only screen and (min-width: 1200px) {-->
<!--  footer {-->
<!--    min-height: 300px;-->
<!--    flex-flow: row nowrap;-->
<!--    align-items: center;-->
<!--  }-->

<!--  footer nav {-->
<!--    flex: 2 1;-->
<!--  }-->

<!--  footer a {-->
<!--    border-left: none;-->
<!--    font-weight: lighter;-->
<!--    font-size: 3em;-->
<!--    text-transform: lowercase;-->
<!--    margin-left: auto;-->
<!--    max-width: 300px;-->

<!--    transition: padding 300ms ease;-->
<!--  }-->

<!--  footer a:hover {-->
<!--    padding-right: 10px;-->
<!--    border-right: 2px solid var(&#45;&#45;text-secondary);-->
<!--  }-->

<!--  footer .footer-text {-->
<!--    flex: 1 0;-->
<!--    flex-flow: column nowrap;-->
<!--    align-items: flex-end;-->
<!--    border-right: 2px solid var(&#45;&#45;text-secondary);-->
<!--    padding-right: 50px;-->
<!--  }-->

<!--  footer .footer-text p {-->
<!--    padding: 20px 0 0 0;-->
<!--  }-->

<!--  footer .footer-text img {-->
<!--    margin: 0 0 20px 20px;-->
<!--  }-->

<!--  footer .google-map {-->
<!--    flex: 0 1 30%;-->
<!--    height: 250px;-->
<!--    margin-left: 50px;-->
<!--    text-align: center;-->
<!--    background-color: rgba(255, 255, 255, 0.6);-->
<!--  }-->
<!--}-->

<!--@media only screen and (min-width: 1650px) {-->
<!--  footer a {-->
<!--    font-size: 4em;-->
<!--  }-->

<!--  footer .footer-text img {-->
<!--    width: 50px;-->
<!--    height: 50px;-->
<!--  }-->
<!--}-->

<!--</style>-->
