<template>
  <form class="contact-form" @submit.prevent="submitMail">
    <slot></slot>
  </form>
</template>

<script>
import emailjs from "@emailjs/browser"

export default {
  name: "ContactForm",
  data: () => {
    return {
      sending: false
    }
  },
  props: {
    templateParams: {
      type: Object,
      required: true
    },
    templateID: {
      type: String,
      default: "dvum_default"
    }
  },
  methods: {
    submitMail () {
      this.sending = true
      this.$emit("sending")

      console.log(this.templateParams)
      console.log(this.templateID)

      // setTimeout(() => {
      //   this.$emit("emailSent")
      //   this.sending = false
      // }, 2000)

      emailjs.send("gmail", this.templateID, this.templateParams, "I8F0Jpjr_1pptcE-J").then((result) => {
        this.sending = false
        this.$emit("emailSent")
        alert("Správa bola odoslaná")
      })
    }
  }
}
</script>

<style>

.contact-form {
  display: flex;
  flex-flow: column nowrap;
}

.contact-form input {
  flex: 0 0 40px;
  margin-bottom: 15px;

  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  padding: 10px 15px;

  border: 2px solid var(--text-secondary);
  border-radius: 5px;
}

.contact-form textarea {
  flex: 1 0 160px;
  margin-bottom: 15px;

  font-size: 1.6em;
  font-family: 'Poppins', sans-serif;
  padding: 10px 15px;

  border: 2px solid var(--text-secondary);
  border-radius: 5px;

  resize: none;
}

.contact-form input:focus, textarea:focus {
  box-shadow: 0px 0px 10px var(--highlight);
  outline: none;
}

.contact-form .lds-dual-ring {
  display: inline-block;
  height: 45px;
  margin: auto;
}

.contact-form .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 4px;
  border-radius: 50%;
  border: 4px solid var(--highlight-transparent);
  border-color: var(--highlight-transparent) transparent var(--highlight-transparent) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
