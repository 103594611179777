<template>
  <Navbar></Navbar>
  <router-view/>
</template>

<script>
import Navbar from "@/components/Navbar"

export default {
  name: "App",
  components: {
    Navbar
  },
  methods: {
    handleHidingElements () {
      const mobile = window.innerWidth < 1200
      this.$store.commit("setIsMobileAs", mobile)
    }
  },
  beforeMount () {
    window.addEventListener("resize", this.handleHidingElements)
    window.addEventListener("load", this.handleHidingElements)

    this.handleHidingElements()
  },
  mounted () {
    const smtpScript = document.createElement("script")
    smtpScript.setAttribute("src", "https://smtpjs.com/v3/smtp.js")
    document.head.appendChild(smtpScript)
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');

html {
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  --background-primary: #ffffff;
  --background-secondary: #e5e5e5;
  --background-secondary-transparent: #e5e5e540;
  --text-primary: #000000;
  --text-secondary: #444444;
  /*--highlight: #4dbcdf;
  --highlight-transparent: #4dbcdf80;*/

  --highlight: #4ddadf;
  --highlight-transparent: #4ddadf80;

  --side-margin: 20px;
  --nav-height: 40px;
  --m-stretch-limit: 600px;
  --m-stretch-limit-large: 800px;
}

#app {
  font-size: 10px;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
  text-decoration: none;
}

h1 {
  font-size: 3em;
  font-weight: normal;
  color: var(--text-primary);
}

h2, h3 {
  font-size: 2.5em;
  font-weight: normal;
  color: var(--text-primary);
}

a {
  font-size: 1.2em;
  color: var(--text-primary);
}

button {
  font-size: 1.5em;
}

p {
  font-size: 1.2em;
  color: var(--text-secondary);
}

li {
  font-size: 1em;
  color: var(--text-primary);
}

.hint {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-top: 20px;
}

.hint img {
  height: 20px;
  width: 20px;
  color: black;
}

.hint p {
  margin: 0 10px !important;
  color: black;
}

.cfa-button {
  height: 45px;
  color: white;
  background-color: var(--highlight);
  border: 4px solid var(--highlight);
  border-radius: 25px;

  transition: 300ms ease;
  transition-property: background-color, color;
}

.cfa-button:hover {
  color: var(--text-secondary);
  background-color: white;
  cursor: pointer;
}

/*IPhone SE (2.gen) a väčšie*/
@media only screen and (min-width: 375px) {
  * {
    --side-margin: 40px;
    --nav-height: 50px;
  }

  h1 {
    font-size: 4em;
  }

  h2, h3 {
    font-size: 3em;
  }

  a {
    font-size: 1.5em;
  }

  p {
    font-size: 1.5em;
  }

  li {
    font-size: 1.2em;
  }
}

/*Plná stránka - tablety, notebooky...*/
@media only screen and (min-width: 1200px) {
  * {
    --side-margin: 80px;
  }

  h1 {
    font-size: 6em;
  }

  h2 {
    font-size: 4em;
  }

  a {
    font-size: 2em;
  }

  button {
    font-size: 2em;
  }

  li {
    font-size: 1.5em;
  }
}

@media only screen and (min-width: 1650px) {
  * {
    --side-margin: 120px;
  }

  h1 {
    font-size: 9.6em;
    color: var(--text-primary);
  }

  h2 {
    font-size: 6em;
  }

  p {
    font-size: 2em;
    color: var(--text-secondary);
  }
}

.align-right {
  margin-left: auto;
}

.align-left {
  margin-right: auto;
}

</style>
