<template>
  <div class="home">
    <Panel id="home-p1" h="99.95vh" @load="homep1">
      <div class="content-wrapper">
        <div class="title-wrapper">
          <p>apartmánová chalupa</p>
          <h1>Dvor u Maliara</h1>
        </div>
        <p class="cfa-paragraph">
          Apartmánová chalupa Dvor u Maliara sa nachádza v krásnom prostredí Liptova na okraji
          obce Liptovský Michal. Hosťom ponúkame ubytovanie s celkovou kapacitou 14 lôžok v štyroch
          samostatných apartmánoch.
        </p>
        <button v-if="this.$store.getters.isPc" class="cfa-button" @click="$router.push('/rezervacie')">REZERVÁCIE
        </button>
        <div v-if="this.$store.getters.isMobile" class="hint">
          <img src="@/assets/svgs/arrow.svg">
        </div>
      </div>
      <div v-if="this.$store.getters.isMobile" class="mobile img-wrapper">
        <img alt="chalupa - apartmány dvor u maliara na liptove" class="bg-img" src="@/assets/photos/out1.jpg">
      </div>
      <div v-if="this.$store.getters.isPc" class="pc img-wrapper">
        <img alt="chalupa - apartmány dvor u maliara na liptove" class="bg-img" src="@/assets/photos/out1.jpg">
      </div>
    </Panel>
    <Panel id="home-p2-1" h="auto" @load="homep2_1">
      <div class="flex-wrapper">
        <div class="align-wrapper">
          <p>ubytovanie vhodné</p>
          <h2>do každej sezóny</h2>
          <video class="seasons-vid" muted loop preload="auto">
            <source src="@/assets/videos/rocne_obdobia_spomal-50fps.mp4" type="video/mp4">
            <source src="@/assets/videos/rocne_obdobia_spomal-50fps.webm" type="video/webm">
          </video>
        </div>
      </div>
    </Panel>
    <Panel id="home-p2-2-m" v-if="this.$store.getters.isMobile" @load="hopep2_2">
      <div class="flex-wrapper">
        <div class="info-block first">
          <img src="@/assets/photos/out1.jpg" alt="jar01.jpg" class="mobile-img">
          <div class="text-block">
            <h2>ubytovanie</h2>
            <p class="text">
              Apartmánová chalupa Dvor u Maliara sa nachádza v krásnom prostredí Liptova na okraji obce Liptovský
              Michal.
              Hosťom ponúkame ubytovanie s celkovou kapacitou 14 lôžok v štyroch samostatných apartmánoch.
              Hostia majú možnosť posedenia v záhrade, kde je altánok, ohnisko, gril a kotlík na guľáš.
              Pre deti máme detské ihrisko, hojdačku, trampolínu.
            </p>
            <div class="hint">
              <img alt="arrow" src="@/assets/svgs/arrow.svg">
              <p>okolie</p>
            </div>
          </div>
        </div>
        <div class="info-block second">
          <img src="@/assets/photos/out1.jpg" alt="jar02.jpg" class="mobile-img">
          <div class="text-block">
            <h2>okolie</h2>
            <p class="text">
              Ubytovanie vhodné pre rodiny s deťmi, skupinky, páriky, športovo založených a pre aktívnu dovolenku.
              V okolí chalupy nájdete veľa atrakcií. V lete sa môžete vydať na prechádzku k Bešeňovským travertínom,
              užiť si kúpanie v obľúbenom vodnom parku Bešeňová, Prírodnom termálnom kúpalisku Kalameny, Kúpeľoch
              Lúčky,
              Aqua Vital Parku Lúčky či Aquaparku Tatralandia.
            </p>
            <div class="hint">
              <img alt="arrow" src="@/assets/svgs/arrow.svg">
              <p>apartmány</p>
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel id="home-p2-2-pc" v-if="this.$store.getters.isPc" @load="hopep2_2">
      <div class="flex-wrapper">
        <div class="images-wrapper" v-if="this.$store.getters.isPc">
          <img src="@/assets/photos/out2.jpg">
        </div>
        <div class="info-wrapper">
          <div class="text-block first">
            <h2>ubytovanie</h2>
            <p class="text">
              Apartmánová chalupa Dvor u Maliara sa nachádza v krásnom prostredí Liptova na okraji obce Liptovský
              Michal.
              Hosťom ponúkame ubytovanie s celkovou kapacitou 14 lôžok v štyroch samostatných apartmánoch.
              Hostia majú možnosť posedenia v záhrade, kde je altánok, ohnisko, gril a kotlík na guľáš.
              Pre deti máme detské ihrisko, hojdačku, trampolínu.
            </p>
            <div class="hint">
              <img alt="arrow" src="@/assets/svgs/arrow.svg">
              <p>okolie</p>
            </div>
          </div>
          <div class="text-block second">
            <h2>okolie</h2>
            <p class="text">
              Ubytovanie vhodné pre rodiny s deťmi, skupinky, páriky, športovo založených a pre aktívnu dovolenku.
              V okolí chalupy nájdete veľa atrakcií. V lete sa môžete vydať na prechádzku k Bešeňovským travertínom,
              užiť si kúpanie v obľúbenom vodnom parku Bešeňová, Prírodnom termálnom kúpalisku Kalameny, Kúpeľoch
              Lúčky,
              Aqua Vital Parku Lúčky či Aquaparku Tatralandia.
            </p>
            <div class="hint">
              <img alt="arrow" src="@/assets/svgs/arrow.svg">
              <p>apartmány</p>
            </div>
          </div>
        </div>
      </div>
    </Panel>
    <Panel id="home-p3" h="100vh" @load="homep3">
      <div class="flex-wrapper">
        <ImageViewport v-if="this.$store.getters.isMobile" ref="aparts-viewport" :sources="this.aparts"/>
        <div class="info-wrapper">
          <h2>apartmány</h2>
          <p>
            Hosťom ponúkame ubytovanie s celkovou kapacitou 14 lôžok v štyroch samostatných apartmánoch. Každý apartmán
            má kuchynskú časť a vlastné sociálne zariadenie (sprcha, WC).
            Na poschodí sa nachádzajú dva dvojlôžkové apartmány s možnosťou prísteliek.
            Na prízemí sú dva štvorlôžkové apartmány s vlastnými vstupmi.
          </p>
          <div class="perks-switching-bar-wrapper">
            <SwitchingBar :options="['dvojlôžkový', 'štvorlôžkový']"
                          @index-changed="(index) => apartsOptionsChanged(index)"/>
            <div class="perks-wrapper">
              <ul>
                <li>podkrovný apartmán</li>
                <li>1x manželská posteľ</li>
                <li>1x prístelka</li>
                <li>kuchynský kút</li>
                <li>kúpeľňa s toaletou</li>
                <li>balkón</li>
              </ul>
              <ul>
                <li>prízemný apartmán</li>
                <li>1x manželská posteľ</li>
                <li>1x prístelka</li>
                <li>kuchynský kút</li>
                <li>kúpeľňa s toaletou</li>
                <li>terasa</li>
              </ul>
            </div>
          </div>
        </div>
        <ImageViewport v-if="this.$store.getters.isPc" ref="aparts-viewport" :sources="this.aparts"/>
      </div>
    </Panel>
  </div>
  <Footer/>
</template>

<script>
import Panel from "@/components/Panels/Panel"
import ImageViewport from "@/components/ImageViewport"
import SwitchingBar from "@/components/SwitchingBar"
import Footer from "@/components/Footer"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

export default {
  name: "Accommodation",
  components: {
    Panel,
    ImageViewport,
    SwitchingBar,
    Footer
  },
  data: () => {
    return {
      aparts: [],
      apartsOptions: [
        [
          "h1.jpg",
          "h2.jpg",
          "h3.jpg",
          "h4.jpg",
          "h5.jpg"
        ],
        [
          "s1.jpg",
          "s2.jpg",
          "s3.jpg",
          "s4.jpg"
        ]
      ]
    }
  },
  methods: {
    homep1 () {
      if (this.$store.getters.isMobile) {
        gsap.from("#home-p1 .img-wrapper", {
          filter: "blur(0px)",
          opacity: 1,
          duration: 1,
          ease: "power1",
          delay: 1
        })

        gsap.from("#home-p1 .title-wrapper, #home-p1 .cfa-paragraph, #home-p1 .cfa-button, #home-p1 .hint", {
          duration: 0.5,
          ease: "power1",
          delay: 1.5,
          opacity: 0,
          y: 25,
          stagger: 0.15
        })
      } else {
        gsap.from("#home-p1 .title-wrapper, #home-p1 .cfa-paragraph, #home-p1 .cfa-button, #home-p1 .hint", {
          duration: 0.5,
          ease: "power1.out",
          delay: 0.5,
          opacity: 0,
          y: 25,
          stagger: 0.15
        })
      }

      gsap.to("#home-p1", {
        opacity: 0,
        ease: "power2.inOut",
        scrollTrigger: {
          trigger: "#home-p1",
          start: "bottom bottom",
          end: "bottom+=300 bottom",
          scrub: 1
        }
      })
    },
    homep2_1 () {
      gsap.to("#home-p2-1 .seasons-vid", {
        scale: 1,
        duration: 0.3,
        scrollTrigger: {
          trigger: "#home-p2-1",
          start: "center center+=200"
        },
        onComplete: () => {
          const vid = document.querySelector("#home-p2-1 .seasons-vid")
          if (vid) {
            vid.playbackRate = 1
            vid.play()
          }
        }
      })

      gsap.to("#home-p2-1 p, #home-p2-1 h2", {
        duration: 0.3,
        ease: "power1.out",
        opacity: 1,
        y: 0,
        delay: 0.3,
        stagger: 0,
        scrollTrigger: {
          trigger: "#home-p2-1",
          start: "center center+=200"
        }
      })
    },
    hopep2_2 () {
      if (this.$store.getters.isMobile) {
        gsap.from("#home-p2-2-m .first .mobile-img, #home-p2-2-m .first h2, #home-p2-2-m .first .text, #home-p2-2-m .first .hint", {
          x: 5,
          y: 25,
          ease: "power1",
          opacity: 0,
          duration: 0.5,
          stagger: 0.15,
          scrollTrigger: {
            trigger: "#home-p2-2-m .first .text-block",
            start: "center bottom"
          }
        })

        gsap.from("#home-p2-2-m .second .mobile-img, #home-p2-2-m .second h2, #home-p2-2-m .second .text, #home-p2-2-m .second .hint", {
          x: 5,
          y: 25,
          ease: "power1",
          opacity: 0,
          duration: 0.5,
          stagger: 0.15,
          scrollTrigger: {
            trigger: "#home-p2-2-m .second .text-block",
            start: "center bottom"
          }
        })
      }

      if (this.$store.getters.isPc) {
        gsap.from("#home-p2-2-pc .first h2, #home-p2-2-pc .first .text, #home-p2-2-pc .first .hint", {
          x: 5,
          y: 25,
          ease: "power1",
          opacity: 0,
          duration: 0.5,
          stagger: 0.15,
          scrollTrigger: {
            toggleActions: "play none none reverse",
            trigger: "#home-p2-2-pc .text-block.first",
            start: "top center"
          }
        })

        gsap.from("#home-p2-2-pc .second h2, #home-p2-2-pc .second .text, #home-p2-2-pc .second .hint", {
          x: 5,
          y: 25,
          ease: "power1",
          opacity: 0,
          duration: 0.5,
          stagger: 0.15,
          scrollTrigger: {
            toggleActions: "play none none reverse",
            trigger: "#home-p2-2-pc .text-block.second",
            start: "top center"
          }
        })

        gsap.to("#home-p2-2-pc img", {
          x: 0,
          scrollTrigger: {
            trigger: "#home-p2-2-pc img",
            start: "center center",
            pin: true,
            endTrigger: "#home-p3",
            end: "top bottom"
          }
        })
      }
    },
    homep3 () {
      gsap.from("#home-p3 h2, #home-p3 p, #home-p3 .perks-switching-bar-wrapper", {
        y: 25,
        ease: "power1",
        opacity: 0,
        duration: 0.5,
        stagger: 0.15,
        scrollTrigger: {
          trigger: "#home-p3",
          start: "top+=75% bottom"
        }
      })
    },
    apartsOptionsChanged (index) {
      this.aparts = this.apartsOptions[index]
    }
  },
  mounted: function () {
    this.homep1()
    this.homep2_1()
    this.hopep2_2()
    this.homep3()
  }
}
</script>

<style>

#home-p1 .bg-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

#home-p1 .mobile.img-wrapper {
  position: absolute;
  inset: calc(var(--side-margin) + var(--nav-height)) var(--side-margin) calc(var(--side-margin) + var(--nav-height)) var(--side-margin);
  filter: blur(5px);
  opacity: 0.15;
}

#home-p1 .pc.img-wrapper {
  position: absolute;
  inset: var(--nav-height) 0 0 60vw;
}

#home-p1 .content-wrapper {
  position: absolute;
  inset: calc((var(--nav-height)) + (2 * var(--side-margin))) var(--side-margin) calc(2 * var(--side-margin)) var(--side-margin);
  z-index: 300;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
}

#home-p1 .title-wrapper {
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  text-align: center;
}

#home-p1 .cfa-paragraph {
  padding: 0 2.5vw 2.5vw 2.5vw;
  text-align: center;
  max-width: 450px;
}

/*IPhone SE (2.gen) a väčšie*/
@media only screen and (min-width: 375px) {

}

/*Plná stránka - tablety, notebooky...*/
@media only screen and (min-width: 1200px) {

  #home-p1 .content-wrapper {
    inset: calc(var(--nav-height) + var(--side-margin)) 50% var(--side-margin) var(--side-margin);
    max-width: 750px;
    align-items: initial;
    align-content: initial;
  }

  #home-p1 .title-wrapper {
    text-align: left;
  }

  #home-p1 .cfa-button {
    width: 350px;
  }

  #home-p1 .cfa-paragraph {
    max-width: 90%;
    padding: 0;
    text-align: left;
  }

  #home-p1 .arrow {
    width: 50px;
    height: 50px;
  }
}

</style> <!-- home-p1 -->

<style>

#home-p2-1 .flex-wrapper {
  margin: calc(var(--side-margin) * 2) var(--side-margin);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#home-p2-1 .align-wrapper {
  max-width: var(--m-stretch-limit-large);
}

#home-p2-1 h2 {
  flex: 0 0;
  border-bottom: 2px solid black;
  margin-bottom: 20px;
  opacity: 0;
  transform: translateY(25px);
}

#home-p2-1 p {
  opacity: 0;
  transform: translateY(50px);
}

#home-p2-1 .seasons-vid {
  transform: scale(0.6);
  pointer-events: none;
  width: 100%;
  min-height: 60vh;
  object-fit: cover;
  border-radius: 5px;
}

@media only screen and (min-width: 1200px) {
  #home-p2-1 .align-wrapper {
    max-width: none;
  }

  #home-p2-1 h2 {
    margin-bottom: 20px;
  }

  #home-p2-1 .seasons-vid {
    height: 70vh;
    width: auto;
    border-radius: 5px;
  }
}

#home-p2-2-m h2, #home-p2-2-pc h2 {
  position: relative;
  padding: 20px 0 8px 0;
}

#home-p2-2-m h2::after, #home-p2-2-pc h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  border-bottom: 2px solid black;
}

#home-p2-2-m p, #home-p2-2-pc p {
  text-align: left;
  margin: 20px 0 0 0;
}

#home-p2-2-m .flex-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

#home-p2-2-m .info-block {
  max-width: var(--m-stretch-limit);
  padding: calc(var(--side-margin) * 2) var(--side-margin);
}

#home-p2-2-m .info-block .mobile-img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

#home-p2-2-m .info-block .text-block {
}

#home-p2-2-pc .flex-wrapper {
  padding: calc(var(--side-margin) * 2) var(--side-margin);
  display: flex;
}

#home-p2-2-pc .images-wrapper {
  flex: 1 1;
}

#home-p2-2-pc .images-wrapper img {
  width: 100%;
  max-height: 75vh;
  border-radius: 5px;
  object-fit: cover;
  border-radius: 5px;
}

#home-p2-2-pc .info-wrapper {
  flex: 1 0;
  height: 200vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

#home-p2-2-pc .text-block {
  max-width: var(--m-stretch-limit);
  margin-left: 30px;
}

</style> <!-- home-p2 -->

<style>

#home-p3 .flex-wrapper {
  position: absolute;
  inset: calc(var(--side-margin) * 2) var(--side-margin);
  max-height: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-content: center;
}

#home-p3 .image-viewport-container {
  flex: 1 1;
}

#home-p3 .info-wrapper {
  flex: 0 1;

  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-content: center;
  max-width: var(--m-stretch-limit-large);
  margin: auto;
}

#home-p3 .info-wrapper h2 {
  position: relative;
  padding: 20px 0 8px 0;
}

#home-p3 .info-wrapper h2::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  border-bottom: 2px solid black;
}

#home-p3 .info-wrapper p {
  text-align: left;
  margin: 20px 0;
}

#home-p3 .perks-wrapper {
  display: none;
}

@media only screen and (min-width: 365px) and (min-height: 700px) {
  #home-p3 .perks-wrapper {
    display: block;
    width: 100%;
  }

  #home-p3 .perks-wrapper ul {
    width: 50%;
    display: inline-block;
    padding: 20px 0 0 20px;
  }
}

@media only screen and (min-width: 1200px) {
  #home-p3 .flex-wrapper {
    flex-flow: row nowrap;
    inset: calc(var(--side-margin) / 2) var(--side-margin);
  }

  #home-p3 .info-wrapper {
    flex-flow: column nowrap;
    flex-basis: 550px;
    margin-right: var(--side-margin);
  }

  #home-p3 image-viewport {
    pointer-events: none;
  }
}

</style> <!-- home-p3 -->
