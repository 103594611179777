<template>
  <div class="reservation">
    <div class="wrap left">
      <SwitchingBar :options="apOptions" @index-changed="(index) => {
        this.currentApIndex = index
        this.calculateExpenses()
      }"/>

      <Calendar v-model="this.dates" :inline="true" selectionMode="range" class="res-calendar" :minDate="new Date()"
                dateFormat="dd.mm.yy"/>
    </div>
    <div class="wrap right">
      <div class="finances">
        <div class="warning" v-if="displayDisclaimer">
          <p>minimálna dĺžka pobytu sú 2 noci</p>
        </div>
        <div class="expense" v-for="e in expenses" :key="e">
          <p>{{ e.title }}</p>
          <p>{{ e.price }}</p>
        </div>
        <span class="estimate">
          <p>odhadovaná cena</p>
          <h3 class="hightlight">{{ tp.ap_estimate }}€</h3>
        </span>
      </div>
      <ContactForm :templateParams="this.tp" templateID="dvum_reservation" @sending="this.sending = true"
                   @emailSent="this.sending = false">
        <input v-model="this.tp.from_name" class="name" name="name" placeholder="Meno" required type="text"/>
        <input v-model="this.tp.from_email" class="address" name="email" placeholder="E-mail" required
               type="email"/>
        <input v-model="this.tp.from_number" class="address" name="email" placeholder="Tel. číslo" required
               type="tel"/>
        <textarea v-model="this.tp.message" class="message" rows="4"/>
        <button class="cfa-button" v-if="!sending && readyToSend" type="submit">ODOSLAŤ</button>
        <div class="lds-dual-ring" v-if="sending"></div>
      </ContactForm>
    </div>
  </div>
</template>

<script>
import Calendar from "primevue/calendar"
import SwitchingBar from "@/components/SwitchingBar"
import ContactForm from "@/components/ContactForm"

export default {
  name: "Reservation",
  components: {
    Calendar,
    SwitchingBar,
    ContactForm
  },
  data: () => {
    return {
      displayDisclaimer: false,
      apOptions: ["štvorlôžkový", "dvojlôžkový"],
      apPrices: [85, 65],
      currentApIndex: Number,
      expenses: [],
      dates: null,
      readyToSend: false,
      sending: false,
      tp: {}
    }
  },
  watch: {
    dates () {
      this.calculateExpenses()
    }
  },
  methods: {
    calculateExpenses () {
      this.expenses = []
      this.readyToSend = false

      if (!this.dates || !this.dates[0] || !this.dates[1] || this.dates[0] === this.dates[1]) return

      const difference = this.dates[1].getTime() - this.dates[0].getTime()
      const nights = Math.ceil(difference / (1000 * 3600 * 24))
      if (nights < 2) {
        this.displayDisclaimer = true
        this.tp.ap_estimate = 0
        return
      }

      this.displayDisclaimer = false

      const nightInSlovak = nights < 5 ? "noci" : "nocí"
      this.tp.ap_nights = nights + " " + nightInSlovak
      this.tp.ap_date_range = this.formatDate(this.dates[0]) + " - " + this.formatDate(this.dates[1])
      this.tp.ap_choice = this.apOptions[this.currentApIndex] + " apartmán"
      this.tp.ap_ppn = this.apPrices[this.currentApIndex] + "€/noc"
      this.tp.ap_estimate = nights * this.apPrices[this.currentApIndex]

      const apChoice = {
        title: this.tp.ap_choice,
        price: this.tp.ap_ppn
      }
      const dateRange = {
        title: this.tp.ap_date_range,
        price: this.tp.ap_nights
      }
      this.expenses.push(
        apChoice,
        dateRange
      )

      this.readyToSend = true

      // DEBUG VALUES
      // console.log(this.tp.ap_nights)
      // console.log(this.tp.ap_date_range)
      // console.log(this.tp.ap_choice)
      // console.log(this.tp.ap_ppn)
      // console.log(this.tp.ap_estimate)
    },
    formatDate (date) {
      return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear()
    }
  }
}
</script>

<style>

.reservation {
  width: 100%;
  height: auto;
  padding: calc(var(--side-margin) * 2) var(--side-margin);
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.reservation .wrap {
  width: 100%;
  max-width: 500px;
}

.reservation .switching-bar {
  margin-bottom: 30px
}

.reservation .p-calendar {
  width: 100%;
}

.reservation .p-datepicker {
  border: 1px solid black;
  border-radius: 5px;
  width: 100%
}

.reservation .p-highlight {
  background-color: var(--highlight-transparent) !important;
}

.reservation .finances {
  margin: 30px 0;
}

.reservation .expense {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid var(--text-secondary)
}

.reservation .warning {
  padding: 5px;
  background-color: var(--highlight-transparent);
  border-radius: 5px;
  color: black;
}

.reservation .estimate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  padding: 0 5px;
  border-bottom: 2px solid black
}

.reservation .contact-form input, .reservation .contact-form textarea {
  border: 1px solid black;
}

@media only screen and (min-width: 1200px) {
  .reservation {
    position: absolute;
    inset: 0 0 0 0;
    flex-flow: row nowrap;
  }

  .reservation .wrap {
    flex: 1 1;
  }

  .reservation .wrap.left {
    margin-right: 20px;
  }

  .reservation .wrap.right {
    margin-left: 20px;
  }

  .reservation .finances {
    margin: 0 0 30px 0;
  }
}

</style>

<style src="primevue/resources/primevue.min.css"></style>
<style src="primeicons/primeicons.css"></style>
<style src="primevue/resources/themes/saga-blue/theme.css"></style>
