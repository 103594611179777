<template>
  <div class="switching-bar">
    <button
      v-for="(item, index) in options" :key="index" @click="selectOfIndex(index)"
      :class="{selected: index === this.currentIndex}">{{item}}
    </button>
  </div>
</template>

<script>
export default {
  name: 'SwitchingBar',
  data: () => {
    return {
      currentIndex: Number
    }
  },
  props: {
    options: {},
    startingIndex: Number
  },
  methods: {
    selectOfIndex (index) {
      this.currentIndex = index
      this.$emit("index-changed", index)
    }
  },
  created () {
    this.currentIndex = this.startingIndex ?? 0
    this.selectOfIndex(this.currentIndex)
  }
}
</script>

<style scoped>

.switching-bar{
  background-color: var(--highlight-transparent);
  border-radius: 5px;
  padding: 5px;

  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
}

.switching-bar button{
  border: none;
  border-radius: 5px;
  background-color: transparent;
  color: var(--text-secondary);
  flex: 1;
  padding: 5px 10px;
  cursor: pointer;

  transition: background-color 300ms ease;
}

.switching-bar button.selected{
  background-color: var(--highlight);
  color: white;
  cursor: default;
}

</style>
